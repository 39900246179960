import React from 'react';

import Link from 'components/Link';

export const HOST_LANDING_STEPS = [
  {
    imgSrc: '/static/landing/list.svg',
    title: 'List Your Space',
    content: (
      <>
        Listing your driveway or parking space on Vanly is completely free. You can set your price
        per night, add as many or as little amenities as you wish, add images and setup your
        calendar.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/calendar.svg',
    title: 'Keep Your Calendar Updated',
    content: (
      <>
        It&rsquo;s really important for our hosts to keep their calendars updated. While you can
        always accept or deny any booking requests, it helps guests know which places are available
        and when.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/stripe.svg',
    title: 'Connect Your Bank Account',
    content: (
      <>
        In order to receive payouts from Vanly, you will need to create a Stripe account. Stripe
        will allow you to transfer the money directly to your bank at any point.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/book_host.svg',
    title: 'Receive Your First Booking Request',
    content: (
      <>
        Once you&rsquo;re all setup and ready to receive guests, you will be notified once you get a
        booking request. You&rsquo;ll know what vehicle the guest will be coming in and other
        information to help you decide whether to accept or deny the booking.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/payment.svg',
    title: 'Receive Payout to Your Stripe Account',
    content: (
      <>
        Congratulations! You&rsquo;re about to host your first guest. But wait. Payment! You will
        receive a payment on your Stripe account the day the guest checks-in.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/meet.svg',
    title: 'Meet New Friends From Around The Country',
    content: (
      <>
        It&rsquo;s completely up to you whether you decide to interact with the guest or not. If you
        {` `}
        decide to interact with the guest, we highly recommend you to follow our{' '}
        <Link href="/blog/covid-guidelines" newTab className="link">
          COVID-19 guidelines
        </Link>
        .
      </>
    ),
  },
  {
    imgSrc: '/static/landing/review.svg',
    title: 'Time For Reviews',
    content: (
      <>
        How was the guest? Were they great? Horrible? Either way, leaving a review will help both
        you and other hosts make their decisions in the future.
      </>
    ),
  },
];

export const GUEST_LANDING_STEPS = [
  {
    imgSrc: '/static/landing/search.svg',
    title: 'Search For Your Ideal Location',
    content: (
      <>
        You can search a location and filter by amenities that best fit your needs, like WiFi,
        access to shower, kitchen, restroom, or even office space. Add the dates you are planning to
        be there to make sure the spots are available.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/book_guest.svg',
    title: 'Book Your First Stay',
    content: (
      <>
        The booking process is really simple. Just add information about your vehicle, a phone
        number and write a message to the host. You will be notified once the host approves or
        denies your booking.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/payment.svg',
    title: 'Payments on Vanly',
    content: (
      <>
        Once the booking is approved, your card will be automatically charged for the amount
        disclosed during the booking process.
      </>
    ),
  },
  {
    imgSrc: '/static/landing/meet.svg',
    title: 'Meet New Friends From Around The Country',
    content: (
      <>
        It&rsquo;s completely up to you whether you decide to interact with the guest or not. If you
        {` `}
        decide to interact with the host, we highly recommend you to follow our{' '}
        <Link href="/blog/covid-guidelines" newTab className="link">
          COVID-19 guidelines
        </Link>
        .
      </>
    ),
  },
  {
    imgSrc: '/static/landing/review.svg',
    title: 'Time For Reviews',
    content: (
      <>
        How was the place? Was everything as described? Were some things different? Either way,
        leaving a review and sharing your experience will help both you and other guests make their
        decisions in the future.
      </>
    ),
  },
];
