import Link from 'components/Link';

import Banner from './Banner';

const PhotographerNetworkBanner = () => {
  return (
    <>
      <Banner
        showBelowTop
        short
        dismissKey="join-photographer-network"
        style={{ background: '#ffd700' }}
      >
        <span className="container is-size-7-mobile" style={{ color: '#800020' }}>
          Explore, photograph, and earn with the Vanly Photographer Network - Start your journey{' '}
          <Link
            href="/photographer-network"
            className="is-underlined has-text-weight-bold"
            style={{ color: '#800020' }}
          >
            here
          </Link>
        </span>
      </Banner>
    </>
  );
};

export { PhotographerNetworkBanner };
