import { useEffect, useState } from 'react';
import { Geolocation, PermissionStatus, PositionOptions } from '@capacitor/geolocation';

import { trackError } from 'lib/tracking';
import { toasts, prompts } from 'components/alert';
import { PLATFORM } from 'lib/env';
import { BehaviorSubject } from 'lib/utils/async';

const positionOptions: PositionOptions = {
  maximumAge: 10000,
};

type PosData = {
  position?: {
    lat: number;
    lng: number;
  };
  permissionDenied?: true;
};

const subject = new BehaviorSubject<PosData>({});

// TODO: HACK! is there a better way to test this? e.g. `err.code === 1`
const isPermissionDenied = (err: Error) =>
  !!err.message && /denied|permission|kclerrordomain\s+error\s+1\b/i.test(err.message);

const handleError = (err: Error, isWatch: boolean, assertPermissions = false) => {
  if (isPermissionDenied(err)) {
    if (assertPermissions) {
      if (PLATFORM === 'android') {
        prompts.alert(`Please enable the location permission in your app settings`);
      } else if (PLATFORM === 'ios') {
        prompts.alert(
          <>
            Please enable the location permission. Go to{' '}
            <code>
              Settings {'>'} Vanly {'>'} Location {'>'} Enable Location While Using the App
            </code>
          </>,
        );
      } else {
        toasts.error(`Please enable location permissions`);
      }
    }

    subject.next({
      permissionDenied: true,
    });
    return;
  }

  trackError(err, isWatch ? 'failed to subscribe to geolocation' : 'failed to get geolocation');
};

let watchId: string | null = null;
let watchCount = 0;

const watchGeo = () => {
  watchCount++;

  if (watchCount === 1) {
    if (watchId) {
      Geolocation.clearWatch({
        id: watchId,
      });
      watchId = null;
    }

    Geolocation.watchPosition(positionOptions, (pos) => {
      if (pos)
        subject.next({
          position: { lat: pos.coords.latitude, lng: pos.coords.longitude },
        });
    })
      .then((thisWatchId) => {
        watchId = thisWatchId;
      })
      .catch((err) => handleError(err, true));
  }

  return () => {
    watchCount--;
    if (watchCount <= 0 && watchId) {
      Geolocation.clearWatch({
        id: watchId,
      });
      watchId = null;
    }
  };
};

/**
 * @param assertPermissions - if true, will show the user a message if permissions were denied
 */
export const refreshGeo = async (assertPermissions = true) => {
  if (assertPermissions) {
    let status: PermissionStatus | undefined;

    try {
      // NOTE: on web, `status` can be `{ location: 'prompt' }` even after denying permissions
      status = await Geolocation.checkPermissions();
    } catch {}

    if (!status || status.location === 'denied') {
      handleError(new Error('permission denied'), false, assertPermissions);

      return null;
    }
  }

  try {
    const { coords } = await Geolocation.getCurrentPosition(positionOptions);

    const position = { lat: coords.latitude, lng: coords.longitude };

    subject.next({
      position,
    });
    return position;
  } catch (err) {
    handleError(err, false, assertPermissions);
    return null;
  }
};

/**
 * Works on web, android, & ios.
 *
 * Be careful enabling `watch`, it will lead to poor battery life on mobile devices
 */
export const useGeolocation = ({
  enabled = true,
  watch = false,
  disableInitialFetch = false,
} = {}) => {
  const [posData, setPosData] = useState<PosData>(subject.value);

  useEffect(() => {
    if (!enabled) {
      setPosData({});
      return;
    }

    let unsub: undefined | (() => void);
    if (watch) unsub = watchGeo();
    else if (!disableInitialFetch) refreshGeo(false);

    const sub = subject.subscribe(setPosData);
    return () => {
      unsub?.();
      sub.unsubscribe();
    };
  }, [enabled, watch, disableInitialFetch]);

  return {
    ...posData,
    refresh: () => refreshGeo(),
  };
};
