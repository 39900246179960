import React from 'react';

import FaqItem from './FaqItem';

const FaqSection = ({ title, items, openFirst = false }) => {
  return (
    <div className="content">
      {title ? (
        <h2 className="has-text-centered" style={{ marginBottom: '3rem' }}>
          {title}
        </h2>
      ) : null}
      <ul className="columns is-multiline content" style={{ margin: 0 }}>
        {items.map((item, i) => (
          <FaqItem question={item.q} answer={item.a} key={i} expanded={openFirst && i === 0} />
        ))}
      </ul>
    </div>
  );
};

export default FaqSection;
