import React from 'react';
import clsx from 'clsx';

const CTATile = ({
  title,
  subtitle,
  isSecondary,
  learnMore,
  learnMoreText,
  children,
  plain = false,
}) => (
  <>
    <div className="column cta-tile">
      <div
        className={clsx(
          !plain && 'cta-tile--box',
          plain && 'cta-tile--box--plain',
          isSecondary && 'inverted',
        )}
      >
        <h2 className="is-size-2 has-text-link has-text-centered has-text-weight-bold">{title}</h2>
        <p className="is-size-5 has-text-centered is-family-secondary subtitle has-text-grey">
          {subtitle}
        </p>
        {children}
        {learnMore ? (
          <div className="learn-more has-text-centered is-family-secondary has-text-grey">
            <a href={learnMore}>{learnMoreText}</a>
          </div>
        ) : null}
      </div>
    </div>

    <style jsx>{`
      @import 'styles/variables';

      .subtitle {
        margin-bottom: 1.5rem;
      }

      .learn-more {
        margin-top: 1rem;
        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .cta-tile--box--plain {
        background: none;
        border: none;
        box-shadow: unset;

        &.inverted {
          h2,
          .subtitle,
          .learn-more {
            color: white !important;
          }
        }
      }

      .cta-tile--box {
        background: none;
        border: none;
        box-shadow: unset;

        &.inverted {
          h2,
          .subtitle,
          .learn-more {
            color: white !important;
          }
        }
      }

      @include landing_show_alt_bg {
        .cta-tile--box {
          background: #eee2;
          border-radius: 16px;
          padding: 1.5rem;
          margin: 0 -1rem;

          h2,
          .subtitle,
          .learn-more {
            color: white !important;
          }
        }
      }
    `}</style>
  </>
);

export default CTATile;
