import { useRef } from 'react';
import * as _ from 'lodash-es';

import Link from 'components/Link';
import { useQuery } from 'lib/hooks/query';
import { useGeolocation } from 'lib/hooks/useGeolocation';
import { encodeQuery } from 'lib/createRoutes.mjs';
import { ListingItem } from 'components/Listing/ListingItem';
import type { NearbyListingsResponse } from 'pages/api/listings/nearby';
import { useIsMobile } from 'components/Responsive';

export const ListingsAroundYou = () => {
  // TODO: call `refreshGeo` somewhere
  const { position: geoPos } = useGeolocation({ disableInitialFetch: true, watch: false });

  const isMobile = useIsMobile();

  const limit = isMobile ? 3 : 6;

  const { data } = useQuery<NearbyListingsResponse>([
    `/api/listings/nearby${encodeQuery(
      {
        featured: 1,
        limit,
        ...(geoPos ? { lat: _.round(geoPos.lat, 3), lng: _.round(geoPos.lng, 3) } : {}),
      },
      true,
    )}`,
    'POST',
  ]);
  const nonnullData = useRef<NearbyListingsResponse | null>(null);
  if (data) nonnullData.current = data;

  const { listings, location_name, search_link: searchLink } = nonnullData.current || {};

  return (
    <>
      <h1 className="has-text-weight-bold is-size-3 mb-4">
        Listings{' '}
        <Link className="has-text-grey-darker hover:underline" href={searchLink || '#'}>
          around {location_name || 'you'}
        </Link>
      </h1>

      <div className="columns is-multiline is-relative">
        {_.times(limit, (i) => {
          const listing = listings?.[i];

          return <ListingItem key={listing?.id ?? i} listing={listing} />;
        })}

        {listings && listings.length <= Math.ceil(limit / 2) ? (
          <p
            className="placeholder has-text-weight-semibold has-text-grey is-size-5 has-text-centered"
            style={listings.length === 0 ? { top: '50%' } : { top: '75%' }}
          >
            Not much here yet, try searching in nearby locations!
          </p>
        ) : null}
      </div>

      <style jsx>{`
        .placeholder {
          position: absolute;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }
      `}</style>

      <div className="has-text-centered">
        {searchLink ? (
          <Link
            href={searchLink}
            className="button is-link is-outlined is-rounded has-text-weight-semibold"
          >
            View more near you
            <i className="fas fa-arrow-right ml-2" aria-hidden />
          </Link>
        ) : (
          <div style={{ height: 36 }} />
        )}
      </div>
    </>
  );
};
