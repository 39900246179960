export const FAQ = [
  {
    q: 'Who can be a Vanly host?',
    a: 'Anyone with extra parking space can be a Vanly host.',
  },
  {
    q: 'How much does it cost to list a driveway space?',
    a: 'Nothing. Listing a new space is completely free.',
  },
  {
    q: 'How are transactions handled?',
    a: 'Transactions are handled by Stripe. Payment methods, payments, and payouts all go through Stripe. Vanly does not store any payment methods.',
  },
  {
    q: 'How does Vanly make money?',
    a: 'We currently charge a small transaction fee to help Vanly run smoothly and cover the cost of maintenance and services. The fee is split between the host and the guest and varies based on a variety of factors. You will see the fee during checkout before you book so you know what to expect. The average for the host is 10% and for the guest 5%. ',
  },
  {
    q: 'How do I cancel a booking?',
    a: "Go to your trips page, click on the booking you'd like to cancel, then from the manage booking page you're going to be able to cancel the booking.",
  },
  {
    q: `How do I contact y'all?`,
    a: 'Please email us at support@vanly.app with any feedback or questions',
  },
  {
    q: `How do I delete my account and my data?`,
    a: 'Please login to your account and go to the "Manage Account" page. From there you will be able to delete your account and all associated data, which takes effect immediately and cannot be reversed.',
  },
];

export const HOST_FAQ = [
  {
    q: 'Who can be a Vanly host?',
    a: 'Anyone with extra parking space can be a Vanly host.',
  },
  {
    q: 'How much does it cost to list a driveway space?',
    a: 'Nothing. Listing a new space is completely free.',
  },
  {
    q: 'How are transactions handled?',
    a: "Transactions are handled through Stripe. As a host, you will have access to a Stripe dashboard where you'll be able to check your balance and keep track of all the records.",
  },
  {
    q: 'Does Vanly charge a fee for transactions?',
    a: 'In order to help Vanly run smoothly and cover the cost of maintenance and products we charge a small fee for each transaction.  The fee is split between the host and the guest and varies based on a variety of factors. The fee for the host is 10% of the transaction amount.',
  },
  {
    q: 'How should I choose the price for my listing?',
    a: 'We leave pricing 100% up to the host. We recommend checking the surrounding areas and using your best estimates to decide on a listing price.',
  },
  {
    q: 'What should I do if I am uncomfortable hosting someone?',
    a: "Ultimately, the host always reserves the right to accept or deny any guest. As a host, you can review a user's profile before deciding on whether to accept their booking request or not.",
  },
];
