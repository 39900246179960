import LazyImage from 'components/LazyImage';

export const SplitContentItem = ({ contentItem, index }) => {
  return (
    <>
      <div
        key={index}
        className="features__columns columns is-variable is-vcentered has-text-centered-mobile"
      >
        <div className="column is-half">
          <h3 className="features__title title has-text-weight-bold is-spaced is-4">
            {contentItem.title}
          </h3>
          <p className="subtitle"> {contentItem.content} </p>
        </div>
        <div className="column">
          <figure className="features__image">
            <LazyImage
              src={contentItem.imgSrc}
              alt=""
              width={300}
              height={280}
              objectFit="contain"
            />
          </figure>
        </div>
      </div>
      <style jsx>{`
        @import 'styles/variables';

        .features {
          &__columns {
            // Reverse every other row
            &:nth-of-type(even) {
              flex-direction: row-reverse;
            }

            @include mobile {
              margin-bottom: 2rem;
            }
          }

          &__title {
            margin-bottom: 1.2rem !important;
          }

          &__image {
            max-width: 300px;
            margin: 30px auto;
          }
        }

        .call-to-action {
          max-width: 500px;
          margin-bottom: 100px;
        }
      `}</style>
    </>
  );
};
