import clsx from 'clsx';
import { useState } from 'react';
import { useInterval } from 'react-use';

export const SwitcherTexts: React.FC<{
  texts: string[];
  maxShownAbove?: number;
  padding?: number;
  interval?: number;
}> = ({
  texts,
  maxShownAbove = 4,
  padding = 0.2, // in `em`
  interval = 2000,
}) => {
  const len = texts.length;

  // TODO: animate with pure CSS
  const [counter, setCounter] = useState(len - 3);

  useInterval(() => {
    setCounter((prev) => prev + 1);
  }, interval);

  return (
    <span className="switcher__wrap">
      &#8203;
      {texts.map((text, i) => {
        const diff = ((counter - i + 1) % len) - (len - 2);

        return (
          <span
            key={i}
            className={clsx('switcher', diff === 0 && 'active')}
            aria-hidden={diff !== 0}
            style={{
              // @ts-expect-error React's style types are broken
              '--bottom': `${-diff * (1 + padding)}em`,
              '--opacity':
                diff === 0
                  ? 1
                  : -diff >= maxShownAbove + 1 || diff === 1
                  ? 0
                  : 1 / Math.abs(diff * 2),
            }}
          >
            {text}
          </span>
        );
      })}
      <style jsx>{`
        @import 'styles/variables';

        .switcher__wrap {
          position: relative;
          display: inline-block;
          width: 9rem;
        }
        .switcher {
          white-space: nowrap;

          text-align: right;
          position: absolute;
          right: 0;

          transition: all 1s ease-in-out;
          transition-property: bottom, opacity, transform;

          user-select: none;
          &.active {
            user-select: auto;
          }

          bottom: var(--bottom);
          opacity: var(--opacity);

          // There's not enough space on mobile
          @include mobile {
            bottom: 0;
            opacity: 0;
            transform: rotateX(90deg);
            transform-origin: bottom;
            &.active {
              opacity: 1;
              transform: none;
            }
          }

          @media (prefers-reduced-motion: reduce) {
            transition: none;
            &:not(.active) {
              display: none;
            }
          }
        }
      `}</style>
    </span>
  );
};
