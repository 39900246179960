import React, { useCallback } from 'react';
import clsx from 'clsx';

import Router from 'lib/router';
import { decodePostalAddress } from 'lib/geo';
import LocationSearchInput from 'components/LocationSearchInput';

const CreateListingAddressSearchBar = ({ className }) => {
  const handleAddressSelect = useCallback(
    ({
      geocoords: { lat, lng } = {},
      street_with_number,
      street_number,
      state,
      city,
      country,
      postal_code,

      notFoundResult,
      searchTerm,
    }) => {
      if (notFoundResult) {
        const parsed = decodePostalAddress(searchTerm);
        Router.pushRoute('host.create', {
          street_with_number: parsed.street_with_number || null,
          street_number: parsed.street_number || null,
        });
      } else
        Router.pushRoute('host.create', {
          lat,
          lng,
          street_with_number,
          street_number,
          state,
          city,
          country,
          postal_code,
        });
    },
    [],
  );

  return (
    <LocationSearchInput
      onSelect={handleAddressSelect}
      icon="home"
      placeholder="Enter your address"
      inputClassName={clsx('input is-medium', className)}
      allowNotFoundResult
    />
  );
};

export default CreateListingAddressSearchBar;
