import { GUEST_LANDING_STEPS, HOST_LANDING_STEPS } from 'lib/constants/features';

import CTATile from 'components/CTATile';
import CreateListingAddressSearchBar from 'components/CreateListingAddressSearchBar';
import ListingsSearchBar from 'components/ListingsSearchBar';
import { SplitContentItem } from 'components/SplitContentItem';

export default function Features({
  style,
  hostOnly = false,
  small = false,
  subject = 'Vanly',
  hideCTA = false,
  hideTitle = false,
  features = hostOnly ? HOST_LANDING_STEPS : GUEST_LANDING_STEPS,
  extraHeaderSpace = false,
}) {
  return (
    <>
      <div className="container" style={style}>
        {!hideTitle && (
          <h2
            className={`${small ? 'is-size-3 has-text-weight-bold' : 'title'} has-text-centered`}
            style={{
              margin: 0,
              padding: '20px 0',
            }}
          >
            How does {subject} work?
          </h2>
        )}
        {extraHeaderSpace && <br />}

        {features.map((feature, index) => (
          <SplitContentItem key={`feature-${index}`} contentItem={feature} index={index} />
        ))}

        {!hideCTA && (
          <div className="call-to-action container">
            {hostOnly ? (
              <CTATile
                title="Get Started"
                subtitle="Start earning money today by listing your space"
                plain
              >
                <CreateListingAddressSearchBar className="c-listing-search" />
              </CTATile>
            ) : (
              <CTATile
                title="Find a place to stay"
                plain
                subtitle="Start exploring now and reserve a spot"
              >
                <ListingsSearchBar className="c-listing-search is-rounded" />
              </CTATile>
            )}
          </div>
        )}
      </div>
    </>
  );
}
