import { useState } from 'react';

import clsx from 'clsx';

import { useSequentialId } from 'lib/utils/sequentialId';

const FaqItem = ({ expanded: initExpanded = false, ...props }) => {
  const [expanded, setExpanded] = useState(initExpanded);
  const panelId = `faq-item-${useSequentialId()}`;

  const handleClick = (event) => {
    event.stopPropagation();
    setExpanded((current) => !current);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
      <li className="column is-half" onClick={handleClick} style={{ marginTop: '0.25rem' }}>
        <h3 className={clsx('is-size-5 has-text-weight-bold', !expanded && 'mb-0')}>
          <button
            className="button-reset"
            onClick={handleClick}
            aria-controls={panelId}
            aria-expanded={expanded}
          >
            <i
              className={clsx('fas has-text-primary', expanded ? 'fa-minus' : ' fa-plus')}
              aria-hidden="true"
            />
            <span className="flex-1">{props.question}</span>
          </button>
        </h3>
        {expanded && (
          <p className="has-text-dark" id={panelId}>
            {props.answer}
          </p>
        )}
      </li>
      <style jsx>{`
        @import 'styles/variables';

        li {
          display: block;
          padding: 1rem;
          outline: none;
          color: $text;
          height: 100%;
          border-radius: 4px;
          cursor: pointer;

          &:hover,
          &:focus-within {
            transition: box-shadow 0.2s ease;
            box-shadow: 0 0 0 2px rgba(22, 23, 26, 0.1);
          }
        }

        h3 {
          text-align: left;
          display: flex;
        }

        button {
          width: 100%;
          display: flex;
          text-align: left;
          align-items: center;

          .fas {
            line-height: 2rem;
            margin-right: 1rem;
          }

          &:focus {
            outline: none;
            span {
              text-decoration: underline;
            }
          }
        }

        p {
          margin-top: 1rem;
        }
      `}</style>
    </>
  );
};

export default FaqItem;
